import { Box, type SpacingAlias } from "@yahoo/uds";
import { I13nAnchor } from "@yahoo-commerce/i13n";
import AutosLogoSvg from "@/svgs/autosLogo.svg";

interface AutosLogoProps {
  spacingBottom?: SpacingAlias;
}

export const AutosLogo = ({ spacingBottom = "6" }: AutosLogoProps) => {
  return (
    <Box spacingBottom={spacingBottom}>
      <I13nAnchor
        href="https://autos.yahoo.com/"
        aria-label="Yahoo Autos"
        dataYlk={{
          elm: "logo",
          slk: "yahoo autos",
        }}
      >
        <AutosLogoSvg height="25" />
      </I13nAnchor>
    </Box>
  );
};

export default AutosLogo;
